<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    entity-type="runeType"
    locale-section="pages.runeTypes"
    field-section="runeTypes"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "RuneTypeDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: runeType
        runeType
        category
      `,
      inputAttributes: {
        runeType: { required: true },
        category: { required: true }
      }
    };
  }
};
</script>
